import React, {useState, useEffect} from 'react';
import {v4 as uuid} from 'uuid';
// Import Router and Logic
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ScrollToTop from './app/logic/ScrollToTop';
import GifPlayer from 'react-gif-player';
import p2LogoGif from './app/assets/gifs/p2_11.gif';
import p2LogoStill from './app/assets/images/p2logo-01.png';
// Import App Styles
import './App.css';
// import 'animate.css/animate.min.css';
import './app/styles/AnimatedButtons.css';
import appStyles from './app/styles/AppStyles';

// Import Navigation
import Navigator from './Navigator';
// Import Https redirect package
import HttpsRedirect from 'react-https-redirect';

// Import Pages
import * as Pages from './app/pages';

// // -----------------------
// Import Contexts
import {CardColorProvider} from './app/context/CardColorContext';
import CaseStudyPage from './app/pages/CaseStudyPage/CaseStudyPage';
export default function App() {
  const [logoPlaying, setLogoPlaying] = useState(p2LogoGif);
  const [wordpressData, setWordpressData] = useState('');
  const startGifPlaying = () => {
    if (logoPlaying !== p2LogoGif) {
      setLogoPlaying(p2LogoGif);
    }
  };
  const stopGifPlaying = () => {
    if (logoPlaying === p2LogoGif) {
      setLogoPlaying(p2LogoStill);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLogoPlaying(null);
    }, 6000);
  }, []);
  useEffect(() => {
    const appUrl =
      'https://www.kingdombranding.com/betas/p2c/wp-json/wp/v2/posts/?_embed';
    fetch(appUrl)
      .then((res) => res.json())
      .then((data) => {
        setWordpressData(data);
      })
      .catch(console.log);
  }, []);
  return (
    <HttpsRedirect>
      <div style={appStyles.app}>
        <Router>
          <ScrollToTop>
            <CardColorProvider>
              <GifPlayer
                autoplay={true}
                onMouseOver={() => {
                  startGifPlaying();
                }}
                onMouseLeave={() => {
                  stopGifPlaying();
                }}
                onTogglePlay={() => {
                  window.location.replace('/');
                }}
                className={'Logo'}
                gif={logoPlaying}
                still={p2LogoStill}
              />
              <Navigator />
              <Switch>
                <Route exact path="/">
                  <Pages.HomePage />
                </Route>
                <Route exact path="/ourWork">
                  <Pages.OurWorkPage />
                </Route>
                <Route exact path="/contactUs">
                  <Pages.ContactPage />
                </Route>
                <Route exact path="/whatWeDo">
                  <Pages.WWDPage />
                </Route>
                <Route exact path="/meetTheTeam">
                  <Pages.TeamPage />
                </Route>
                <Route exact path="/thankyou">
                  <Pages.ThankyouPage />
                </Route>
                {wordpressData
                  ? wordpressData.map((post, index) => {
                    var nextPost =
                      index !== wordpressData.length
                        ? wordpressData[index + 1]
                        : wordpressData[0];
                    var condensedCaseStudyTitle = post.title.rendered.replace(
                      /\s/g,
                      '-',
                    );
                    return (
                      <Route
                        key={uuid()}
                        exact
                        path={`/case-studies/${condensedCaseStudyTitle}`}>
                        <CaseStudyPage
                          nextPage={nextPost}
                          content={post.content.rendered.replace(
                            /<\/?[^>]+>/gi,
                            '',
                          )}
                          post={post}
                          title={post.title.rendered}
                          summary={post.excerpt.rendered.replace(
                            /<\/?[^>]+>/gi,
                            '',
                          )}
                        />
                      </Route>
                    );
                  })
                  : null}
                <Route exact>
                  <Pages.ErrorPage />
                </Route>
              </Switch>
            </CardColorProvider>
          </ScrollToTop>
        </Router>
      </div>
    </HttpsRedirect>
  );
}
