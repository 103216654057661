import React, {Component} from 'react';
import whiteP2 from '../../../assets/images/p2logo-white.png';
import '../styles/footer.css';

export default class FootLogo extends Component {
  render() {
    return (
      <div>
        <div className={'Footer-Logo'}>
          <img src={whiteP2} className={'Logo-Style'} alt="whiteLogo" />
          <p
            style={{
              color: 'white',
              fontSize: 18,
              paddingTop: 20,
            }}>
            2023 P2 Consulting.
          </p>
        </div>
      </div>
    );
  }
}
