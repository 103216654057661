import React from 'react';
import {Slide} from 'react-reveal';
export default function Info(props) {
  return (
    <Slide wait={100} delay={200} duration={1000} up>
      <div
        className={
          props.justify === 'right'
            ? 'Row-Container-Right'
            : props.justify === 'left'
              ? 'Row-Container-Left'
              : 'Row-Container-Right'
        }>
        <div
          className={
            props.justify === 'right'
              ? 'Info-Container'
              : props.justify === 'left'
                ? 'Info-Container-2'
                : 'Info-Container'
          }>
          <h2 className={'Black-Header-2'}>{props.header}</h2>
          <p className={'Paragraph'}>{props.paragraph}</p>
        </div>
      </div>
    </Slide>
  );
}
