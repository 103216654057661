const appData = {
  company: {
    slogan:
      'P2 Consulting is a boutique strategic consulting firm specializing in fundraising. Since 2009, P2 has raised millions for its clients across the political and nonprofit spectrum. P2 brings decades of collective institutional, political and nonprofit experience to work for you. P2 represents clients on the local, county, state and federal level.',
    philosophy:
      'Our philosophy is simple: we offer a tailored strategy, with extraordinary results. Whereas other firms may present cookie-cutter fundraising campaigns, P2 works closely with clients to develop a unique framework that will maximize success. Your business is personal to us.',
  },
  whatWeDo: {
    paragraphs: {
      fundraising:
        'P2 offers the broadest range of fundraising services using a national network of highly vetted donors. We put our vast fundraising network to work for you.',
      communications:
        'Words matter. Whether you have a fully developed platform or you’re starting from scratch, P2 can help make your message resonate.',
      generalConsulting:
        'As your strategic advisor, we will help you put together a winning team. We’re experts at building movements by engaging grassroots supporters, coalitions, partners, policymakers, and donors.',
      eventManagement:
        'P2 offers a one-stop shop from concept through execution. We combine our creativity, eye for detail, and execution expertise to ensure your event goes off without a hitch.',
      research:
        'Truth is in the numbers. P2 works with top notch data analysts to provide insights on best practices to measure, target and persuade diverse audiences.',
      development:
        'P2 brings strength to not only political campaigns but the nonprofit sector as well through guidance, resources, and crisis development funding.',
    },
    bullets: {
      fundraising: [
        'Donor Research Prospecting',
        'Donor Out Reach',
        'Campaign Compliance',
        'Finance Plans/Strategy',
        'Coaching & Training',
        'Digital Fundraising',
      ],
      communications: [
        'Speechwriting',
        'Media Relations',
        'Press Outreach',
        'Media Training',
        'Debate Preparation',
        'Social Media Content',
        'Message Development',
      ],
      generalConsulting: [
        'Campaign Plan',
        'Budget Creation',
        'Team Assembly & Management',
      ],
      eventManagement: [
        'Logistics, Production & On-Site Management',
        'Project Management & Administration',
        'Program Development & Speaker Management',
        'Event Branding & Communication',
        'Sponsorship Support',
        'Registration Management',
        'Post Event Evaluation &',
        'Acknowledgements',
      ],
      research: [
        'Qualitative Research',
        'Data Analysis',
        'Polling',
        'Self/Oppo Research',
        'Voter Targeting',
      ],
      development: [
        'Crisis Communications',
        'Strategic Messaging',
        'Community Engagement',
        'Board & Staff Development',
        'Policy Platform & Issue Development',
        'Grant Research',
        'Budget Management',
      ],
    },
  },
};
export default appData;
