// Import Components
import React, {useState, useRef} from 'react';
import Footer from '../../components/Footer/Footer';
import {confirmAlert} from 'react-confirm-alert';
import SendEmail from '../../logic/SendEmail';
// Import Style
import './ContactPage.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
/**
 * @returns Contact Page
 */
function ContactPage() {
  const form = useRef();
  const [formDisabled, setFormDisabled] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  // let contactInfo = {
  //   firstName: firstName,
  //   lastName: lastName,
  //   company: company,
  //   email: email,
  //   phone: phone,
  //   message: message
  // };
  // const [alert, setAlert] = useState(false);
  const requestContact = () => {
    if (email && message) {
      confirmAlert({
        title: 'Confirm Contact',
        message: `Thank you for your contact request ${firstName}, please confirm that this is the right email: ${email}.`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              // Sending Email
              try {
                SendEmail({form: form});
                setFormDisabled(true);
              } catch (error) {
                confirmAlert({
                  title: 'Contact Form Error',
                  message: error.message,
                });
              }
            },
          },
          {
            label: 'No',
            onClick: () => {
              return null;
            },
          },
        ],
      });
    }
  };
  return (
    <div className={'Contact-Page'}>
      <div className={'Contact-Body'} height={'100vh'}>
        <div className={'Contact-Column-Left'}>
          <h2 className={'Contact-Header-1'}>Contact Us</h2>
          <h1 className={'Contact-Header-2'}>
              Looking to <span>work</span> with us?
          </h1>
          <p className={'Contact-Header-Paragraph'}>
              Fill out the form and we will get back to you as soon as possible.
          </p>
        </div>
        <div className={'Contact-Column-Right'}>
          {formDisabled ?
            <p className={'Thank-You'}>
              Contact Submission Sent!
            </p>
            :
            <form
              ref={form}
              onSubmit={(e) => {
                e.preventDefault();
                requestContact();
              }}
              className={'Contact-Form-Grid'}
            >
              <div className={'Input-Container-1'}>
                First Name
                <input
                  disabled={formDisabled}
                  name="firstName"
                  value={firstName}
                  onChange={(e) => {
                    e.preventDefault();
                    setFirstName(e.target.value);
                  }}
                  className={'Input'}
                  type="name"
                />
              </div>
              <div className={'Input-Container-2'}>
                Last Name
                <input
                  disabled={formDisabled}
                  name="lastName"
                  value={lastName}
                  onChange={(e) => {
                    e.preventDefault();
                    setLastName(e.target.value);
                  }}
                  className={'Input'}
                  type="name"
                />
              </div>
              <div className={'Input-Container-3'}>
                Company
                <input
                  disabled={formDisabled}
                  name="company"
                  value={company}
                  onChange={(e) => {
                    e.preventDefault();
                    setCompany(e.target.value);
                  }}
                  className={'Input'}
                  type="company"
                />
              </div>
              <div className={'Input-Container-4'}>
                Email
                <input
                  disabled={formDisabled}
                  name="email"
                  value={email}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmail(e.target.value);
                  }}
                  className={'Input'}
                  type="email"
                />
              </div>
              <div className={'Input-Container-5'}>
                Phone Number
                <input
                  disabled={formDisabled}
                  name="phone"
                  value={phone}
                  onChange={(e) => {
                    e.preventDefault();
                    setPhone(e.target.value);
                  }}
                  className={'Input'}
                  type="numeric"
                />
              </div>
              <div className={'Input-Container-6'}>
                Message
                <textarea
                  disabled={formDisabled}
                  rows={3}
                  name="message"
                  value={message}
                  onChange={(e) => {
                    e.preventDefault();
                    setMessage(e.target.value);
                  }}
                  className={'Input-2'}
                  type="numeric"
                />
              </div>
              <div className={'Input-Submit'}>
                <div className={'Basic-Button-Container'}>
                  <button
                    disabled={formDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      requestContact();
                    }}
                    className={'Basic-Button'}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
