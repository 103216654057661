import React from 'react';
import {Slide} from 'react-reveal';
export default function WWDHeading(props) {
  return (
    <div className={'What-We-Do-Header-Container'}>
      <h2 className={'What-We-Do-Header-1'}>What We Do</h2>
      <h1 className={'What-We-Do-Header-2'}>
        {/* We wear a lot of <span className={'Hats-Container'}>hats</span> */}
      </h1>
      <Slide delay={500} bottom>
        <div className={'Heading-Paragraph'}>{props.paragraph}</div>
      </Slide>
    </div>
  );
}
