import React, {useState} from 'react';
import TwitterIconBlack from '../../../assets/svgs/Social-Icons-04.svg';
import TwitterIconBlue from '../../../assets/svgs/Social-Icons-04-blue.svg';
import TwitterIconWhite from '../../../assets/svgs/Social-Icons-04-white.svg';
// import TwitterIconRed from '../../../assets/svgs/Social-Icons-04-red.svg';
import './icon.css';

export default function TwitterIcon(props) {
  const [isHovering, setIsHovering] = useState(Boolean);
  return (
    <div
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={{
        height: props.height,
        width: props.width,
        margin: '8px',
      }}>
      {props.color === 'white' ? (
        isHovering ? (
          <img src={TwitterIconBlack} alt="Twitter Icon" />
        ) : (
          <img src={TwitterIconWhite} alt="Twitter Icon" />
        )
      ) : props.color === 'black' ? (
        isHovering ? (
          <img src={TwitterIconBlack} alt="Twitter Icon" />
        ) : (
          <img src={TwitterIconBlue} alt="Twitter Icon" />
        )
      ) : null}
    </div>
  );
}
