import React from 'react';
import {Slide} from 'react-reveal';
export default function CaseStudyCard(props) {
  let cardImage = props.image;
  return (
    <Slide wait={100} delay={200} duration={1000} up>
      <a
        className={props.ourWork ? 'Our-Work-Link' : 'Case-Study-Link'}
        href={props.href}>
        <div className={'Case-Study-Card'}>
          <div className={'Case-Study-Summary'}>
            <div className={'Case-Study-Summary-Arrow'}>
              &#x2191; {'Case Study'}
            </div>
            <div className={'Case-Study-Summary-Text'}>{props.summary}</div>
          </div>
          <img
            className={'Case-Study-Image'}
            src={cardImage}
            alt="Case_Study"
          />
          <div className={'Case-Study-Card-Title'}>{props.text}</div>
        </div>
      </a>
    </Slide>
  );
}
