import React, {useState} from 'react';
import LinkedInIconBlack from '../../../assets/svgs/Social-Icons-02.svg';
import LinkedInIconBlue from '../../../assets/svgs/Social-Icons-02-blue.svg';
import LinkedInIconWhite from '../../../assets/svgs/Social-Icons-02-white.svg';
// import LinkedInIconRed from '../../../assets/svgs/Social-Icons-02-red.svg';
import './icon.css';

export default function LinkedInIcon(props) {
  const [isHovering, setIsHovering] = useState(Boolean);
  return (
    <div
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={{
        height: props.height,
        width: props.width,
        margin: '8px',
      }}>
      {props.color === 'white' ? (
        isHovering ? (
          <img src={LinkedInIconBlack} alt="LinkedIn Icon" />
        ) : (
          <img src={LinkedInIconWhite} alt="LinkedIn Icon" />
        )
      ) : props.color === 'black' ? (
        isHovering ? (
          <img src={LinkedInIconBlack} alt="LinkedIn Icon" />
        ) : (
          <img src={LinkedInIconBlue} alt="LinkedIn Icon" />
        )
      ) : null}
    </div>
  );
}
