// Import Components
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';
import SocialMediaIcons from './app/components/basic/SocialMediaIcons';
// Import Navigator Styles
import appStyles from './app/styles/AppStyles';
// Import Media
import logoImg from './app/assets/images/p2logo-white.png';
export class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  keyPress(e) {
    if (e.key === 'esc') {
      this.setState({
        open: !this.state.open,
      });
    }
  }
  handleClick() {
    this.setState({
      open: !this.state.open,
    });
  }
  render() {
    if (this.state.open) {
      return (
        <nav onKeyPress={this.keyPress.bind(this)} className={'Navigator-Menu'}>
          <a href="/" className={'Logo-Container'}>
            <img className={'Logo-White'} src={logoImg} alt="logo" />
          </a>
          <div className={'Hamburger-Container-2'}>
            <HamburgerMenu
              isOpen={this.state.open}
              menuClicked={this.handleClick.bind(this)}
              width={25}
              height={18}
              strokeWidth={3}
              rotate={0}
              color={appStyles.colors.white}
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
          <div className={'Navigator-Link-Container'}>
            <Link
              className={'Navigator-Link'}
              onClick={this.handleClick.bind(this)}
              to="/">
              <div className={'Navigator-Link-Text'}>Home</div>
            </Link>
            <Link
              className={'Navigator-Link'}
              onClick={this.handleClick.bind(this)}
              to="/whatWeDo">
              <div className={'Navigator-Link-Text'}>What We Do</div>
            </Link>
            <Link
              className={'Navigator-Link'}
              onClick={this.handleClick.bind(this)}
              to="/meetTheTeam">
              <div className={'Navigator-Link-Text'}>Meet the Team</div>
            </Link>
            {/* <Link
              className={'Navigator-Link'}
              onClick={this.handleClick.bind(this)}
              to="/ourWork">
              <div className={'Navigator-Link-Text'}>Our Work</div>
            </Link> */}
            <Link
              className={'Navigator-Link'}
              onClick={this.handleClick.bind(this)}
              to="/contactUs">
              <div className={'Navigator-Link-Text'}>Contact</div>
            </Link>
            <div className={'Navigator-Social-Media'}>
              <SocialMediaIcons color={'white'} />
            </div>
          </div>
        </nav>
      );
    } else {
      return (
        <div className={'Hamburger-Container'}>
          <a href="/contactUs" className={'Work-With-Us-Link'}>
            Work With Us
          </a>
          <HamburgerMenu
            isOpen={this.state.open}
            menuClicked={this.handleClick.bind(this)}
            width={25}
            height={18}
            strokeWidth={3}
            rotate={0}
            color={appStyles.colors.primary}
            borderRadius={0}
            animationDuration={0.5}
            zIndex={305}
          />
        </div>
      );
    }
  }
}

export default Navigator;
