import React, {Component} from 'react';
import HeadingD from './footComps/Heading';
// import FootLinks from './footComps/FootLinks';
import FootLogo from './footComps/FootLogo';
import Email from './footComps/Email';
import './styles/footer.css';

export class Footer extends Component {
  render() {
    return (
      <div>
        <footer className={'Footer'}>
          <div className={'Jumbo-Style'}>
            <HeadingD />
            {/* <FootLinks /> */}
            <div className={'Footer-Logo-Row'}>
              <FootLogo />
              <Email />
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
