import React, {Component} from 'react';
import BasicButton from '../../components/basic/BasicButton';
import './ErrorPage.scss';
class ErrorPage extends Component {
  render() {
    return (
      <div className={'Error-Page-Container'}>
        <div className={'Error-Container'}>
          <div className={'Error-Page-Text'}>404 Error, Location Not Found</div>
          <BasicButton
            href={'/'}
            buttonContainerType={'Basic-Button-Container'}
            buttonType={'Basic-Button'}
            color={'rgb(238,52,34)'}
            text={'Go Home'}
          />
        </div>
      </div>
    );
  }
}

export default ErrorPage;
