/* eslint-disable indent */
// Import Components
import React, {useState, useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
// Import Style
import './CaseStudyPage.css';
import ElevatingUnderdog from './components/ElevatingUnderdog';
import Headline from './components/Headline';
import NextCaseStudy from './components/NextCaseStudy.js';
import appStyles from '../../styles/AppStyles';
import {Slide} from 'react-reveal';

import parse from 'html-react-parser';
/**
 *
 * @param {props.post.content.rendered} Wordpress Post/Case Study Data
 * @returns Case Study Page based on data from the Wordpress API
 */
export default function CaseStudyPage(props) {
  // Images
  const [headerImage, setHeaderImage] = useState('');
  const [mainBodyImage, setMainBodyImage] = useState('');
  const [leftSquareImage, setLeftSquareImage] = useState('');
  const [rightSquareImage, setRightSquareImage] = useState('');
  // Text
  const [summary, setSummary] = useState('');
  const [elevatingSubHeader, setElevatingSubHeader] = useState('');
  const [elevatingParagraph, setElevatingParagraph] = useState('');
  const [bodyParagraph1, setBodyParagraph1] = useState('');
  const [bodyParagraph2, setBodyParagraph2] = useState('');
  let html = props.post.content.rendered;
  let parsedHtml = parse(html);
  const [heroStage, setHeroStage] = useState(0);
  useEffect(() => {
    parsedHtml.map((element) => {
      if (element) {
        if (element.type === 'figure') {
          let caption = element.props.children[1].props.children;
          if (caption === 'Hero Image') {
            setHeaderImage(element.props.children[0].props.src);
          } else if (caption === 'Main Body Image') {
            setMainBodyImage(element.props.children[0].props.src);
          } else if (caption === 'Left Square Image') {
            setLeftSquareImage(element.props.children[0].props.src);
          } else if (caption === 'Right Square Image') {
            setRightSquareImage(element.props.children[0].props.src);
          }
        }
        if (element.type === 'h2') {
          setElevatingSubHeader(element.props.children);
        }
        if (element.type === 'p') {
          if (element.props.className === 'summary') {
            setSummary(element.props.children);
          } else if (element.props.className === 'elevating-paragraph') {
            setElevatingParagraph(element.props.children);
          } else if (element.props.className === 'body-paragraph-1') {
            setBodyParagraph1(element.props.children);
          } else if (element.props.className === 'body-paragraph-2') {
            setBodyParagraph2(element.props.children);
          }
        }
        // if (element.type === 'ul') {
        //   console.log(`ul: ${element.props.children}`);
        // }
      }
    });
    setTimeout(() => {
      setHeroStage(1);
    }, 1500);
  }, [heroStage, setHeroStage, parsedHtml]);
  let heroCaseSwitch = () => {
    switch (heroStage) {
      case 0:
        return (
          <div
            className={'Case-Study-Hero-Stage-0'}
            style={{backgroundImage: `url(${headerImage}`}}>
            <div className={'Case-Study-Page-Column-Left'}>
              <Slide down wait={50} delay={10} duration={1000}>
                <h1 className={'Case-Study-Page-Header-2'}>{props.title}</h1>
              </Slide>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={'Case-Study-Hero-Stage-1'}>
            <Slide
              className={'Case-Study-Hero-Slide'}
              right
              wait={50}
              delay={10}
              duration={1000}>
              <div
                className={'Case-Study-Hero-Image'}
                style={{backgroundImage: `url(${headerImage}`}}
              />
            </Slide>
            <div className={'Case-Study-Page-Column-Right'}>
              <h1 className={'Case-Study-Page-Header-3'}>{props.title}</h1>
              <p className={'Case-Study-Page-Header-Paragraph'}>{summary}</p>
            </div>
          </div>
        );
    }
  };
  return (
    <div className={'Case-Study-Page'}>
      {heroCaseSwitch()}
      <body className={'Case-Study-Page-Body'} height={'100vh'}>
        <ElevatingUnderdog
          subHeader={elevatingSubHeader}
          paragraph={elevatingParagraph}
        />
        <Slide up duration={1000} delay={300} wait={800}>
          <div className={'Case-Study-Image-Top-Container'}>
            <img src={mainBodyImage} className={'Case-Study-Image-Top'} />
          </div>
        </Slide>
        <div className={'Case-Study-Middle-Section'}>
          <Headline paragraph={bodyParagraph1} color={'#6b90de'} />
        </div>
        <div className={'Case-Study-Middle-Image-Container'}>
          <Slide left duration={1000} delay={500} wait={800}>
            <div
              className={'Middle-Image-Left'}
              style={{
                backgroundImage: 'url(' + leftSquareImage + ')',
              }}
            />
          </Slide>
          <Slide right duration={1000} delay={500} wait={800}>
            <div
              className={'Middle-Image-Right'}
              style={{
                backgroundImage: 'url(' + rightSquareImage + ')',
              }}
            />
          </Slide>
        </div>
        <div className={'Case-Study-Bottom-Section'}>
          <div className={'Bottom-Section-Content-Container'}>
            <div className={'Blue-Block'} />
            <div className={'We-Raised-Container'}>
              <Headline
                paragraph={bodyParagraph2}
                color={appStyles.colors.primary}
                weRaised={true}
              />
            </div>
          </div>
        </div>
        <NextCaseStudy nextPage={props.nextPage} />
      </body>
      <div className={'Case-Study-Page-Footer'}>
        <Footer />
      </div>
    </div>
  );
}
