import React, {Component} from 'react';
import SocialMediaIcons from '../../basic/SocialMediaIcons';

export default class Email extends Component {
  render() {
    return (
      <div className={'Social'}>
        <div className={'Footer-Email'}>
          <p style={{color: 'white'}}>info@p2consulting.com</p>
        </div>
        <SocialMediaIcons color={'white'} />
      </div>
    );
  }
}
