import React, {Component} from 'react';
import Footer from '../../components/Footer/Footer';
import OurWorkHero from './components/OurWorkHero';
import CSgrid from './components/CSgrid';
import './ourWork.css';
export class OurWorkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: 'CARD',
      rows: 20,
      data: [0, 1, 2],
    };
  }
  render() {
    return (
      <div>
        <header>
          <OurWorkHero />
        </header>
        <CSgrid />
        <Footer />
      </div>
    );
  }
}

export default OurWorkPage;
