import React from 'react';
import '../mtt.scss';
// import AnimateOnScroll from '../../../logic/AnimateOnScroll';

export default function TeamCard(props) {
  /**
   * TeamCard functional component.
   * *This functional component renders the team member's cards.
   * *Features props from TeamPage.js that give the card the name, title, and bio of a member.
   * *Features an onClick function that changes a state to reveal a bio inside of the member's pic container on click, which when the user clicks again, it fades the pic back in.
   * *Features an animated "Slide" arrow button.
   */

  return (
    <div className={'Content-Container'}
    // ref={ref}
    >
      <div
        className={'Member-Pic-Container'}
        style={{backgroundImage: props.memberPortrait}}
      >
      </div>
      <div className={'Name-Container'} >
        <h2 className={'membername'}>{props.name}</h2>
        <h4 className={'Member-Title'}>{props.title}</h4>
        <p className={'Team-Bio'}>{props.bio}</p>
      </div>
    </div>
  );
}
