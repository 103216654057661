import React from 'react';
import '../CaseStudyPage.css';
/**
 *
 * @param {props.color} Color for Header Font Color
 * @param {props.paragraph} Paragraph text data for function
 * @returns Headline Section with information in a paragraph
 */
export default function Headline(props) {
  if (props.weRaised) {
    return (
      <div className={'Headline-Container'}>
        <h3 className={'Headline-Sub-Header'} style={{color: props.color}}>
          Subhead
        </h3>
        <h2 className={'Headline-Title'}>Headline</h2>
        <p className={'Headline-Paragraph'}>{props.paragraph}</p>
        <h3 className={'We-Raised-Text'}>We raised</h3>
        <h2 className={'Money-Raised-Text'}>$Num</h2>
      </div>
    );
  } else {
    return (
      <div className={'Headline-Container'}>
        <h3 className={'Headline-Sub-Header'} style={{color: props.color}}>
          Subhead
        </h3>
        <h2 className={'Headline-Title'}>Headline</h2>
        <p className={'Headline-Paragraph'}>{props.paragraph}</p>
      </div>
    );
  }
}
