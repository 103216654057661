import React from 'react';
import {FBIcon, LinkedIcon, TwitterIcon} from '../basic/icons';
export default function SocialMediaIcons(props) {
  return (
    <div className={'Social-Media-Icons-Container'}>
      <a
        href="https://www.facebook.com/p2consults"
        target="_blank"
        rel="noreferrer"
        className={'Social-Link'}>
        <FBIcon color={props.color} width={'19px'} height={'19px'} />
      </a>
      {/* <a
        href="https://www.instagram.com/"
        target="_blank"
        rel="noreferrer"
        className={'Social-Link'}>
        <InstaIcon color={props.color} width={'19px'} height={'19px'} />
      </a> */}
      <a
        href="https://twitter.com/P2Consults"
        target="_blank"
        rel="noreferrer"
        className={'Social-Link'}>
        <TwitterIcon color={props.color} width={'18.5px'} height={'18.5px'} />
      </a>
      <a
        href="https://www.linkedin.com/company/p2-consulting-inc./about/"
        target="_blank"
        rel="noreferrer"
        className={'Social-Link'}>
        <LinkedIcon color={props.color} width={'18.5px'} height={'18.5px'} />
      </a>
    </div>
  );
}
