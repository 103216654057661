import React, {Component} from 'react';
import {Slide} from 'react-reveal';
import {v4 as uuid} from 'uuid';
export class InfoBullet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      header: props.header,
      paragraph: props.paragraph,
      subHeader: props.subHeader,
      bullets: props.bullets,
      accordionOpen: false,
    };
  }
  render() {
    const clickAccordion = () => {
      this.setState({accordionOpen: !this.state.accordionOpen});
    };
    return (
      <div
        key={this.state.id}
        id={this.state.id}
        onClick={clickAccordion}
        className={'Info-Bullet-Container'}>
        <div className={'Info-Bullet-Header'}>{this.state.header}</div>
        <div className="Info-Bullet-Right">
          <div className={'Info-Bullet-Paragraph'}>{this.state.paragraph}</div>
          <Slide delay={400} bottom>
            <div className="Info-Bullet-What-Exactly">
              <div className="Info-Bullet-Sub-Header-Container">
                <div className={'Info-Bullet-Sub-Header'}>
                  {this.state.subHeader}
                </div>
                <div className="Info-Bullet-Button">
                  {this.state.accordionOpen ? '-' : '+'}
                </div>
              </div>
              {this.state.accordionOpen ? (
                <ul className={'Info-Bullet-List-Open'}>
                  {this.state.bullets.map((bullet) => {
                    return (
                      <li
                        key={uuid()}
                        className={
                          this.state.accordionOpen
                            ? 'Info-Bullet-Open'
                            : 'Info-Bullet-Closed'
                        }>
                        {bullet}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div
                  onClick={clickAccordion}
                  className="Info-Bullet-List-Closed"
                />
              )}
            </div>
          </Slide>
        </div>
      </div>
    );
  }
}

export default InfoBullet;
