/* eslint-disable react/no-unescaped-entities */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../styles/footer.css';

export default class HeadingD extends Component {
  render() {
    return (
      <div>
        <div className={'Footer-Heading'}>
          <h1 className={'Footer-Title'}>Let's create change.</h1>
          <div className={'White-Button-Container'}>
            <Link to="/ContactUs" className={'Link-Button-Container'}>
              <button className={'White-Button'}>Contact Us</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
