import React, {useState} from 'react';
export default function BasicButton(props) {
  const [textColor, setTextColor] = useState(props.color);
  let mouseOver = () => {
    if (textColor === 'rgb(238,52,34)') {
      setTextColor('white');
    } else if (textColor === 'white') {
      setTextColor('rgb(238,52,34)');
    } else if (textColor === 'black') {
      setTextColor('rgb(250, 250, 250)');
    }
  };
  let mouseOut = () => {
    if (textColor === 'rgb(238,52,34)') {
      setTextColor('white');
    } else if (textColor === 'white') {
      setTextColor('rgb(238,52,34)');
    } else if (textColor === 'rgb(250, 250, 250)') {
      setTextColor('black');
    }
  };
  return (
    <div className={props.buttonContainerType}>
      <a href={props.href}>
        <button
          className={props.buttonType}
          onMouseOver={mouseOver}
          onMouseOut={mouseOut}
          style={{
            fontFamily: 'Didot',
            fontStyle: 'italic',
            background: 'transparent',
            fontWeight: props.fontWeight,
            border: '1.5px solid',
            borderWidth: props.borderWidth,
            borderColor: props.color,
            color: textColor,
            boxShadow: '0 0 1px black',
            margin: props.margin,
            marginTop: props.marginTop,
            marginBottom: props.marginBottom,
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
          }}>
          {props.text}
        </button>
      </a>
    </div>
  );
}
