import React, {Component} from 'react';
// Import Styles
import './Home.scss';

// Import Components
import Hero from './components/Hero';
// import HomeCaseStudies from './components/HomeCaseStudies';
import Paragraph from '../../components/basic/Paragraph';
import VisionBoard from './components/VisionBoard';
// import SubscribeForm from './components/SubscribeForm';
import Footer from '../../components/Footer/Footer';
import appData from '../../assets/data/appData';
import OurRange from '../WhatWeDoPage/components/OurRange';
/**
 * @returns Home Page
 */
export class HomePage extends Component {
  render() {
    return (
      <div>
        <header className={'App-Hero'}>
          <Hero />
        </header>
        <div className={'Home-Body'}>
          <section className={'Home-Section-1'}>
            <Paragraph
              justify={window.innerWidth > 600 ? 'right' : 'center'}
              paragraph={appData.company.slogan}
            />
            {/* <HomeCaseStudies pagePosition={1} /> */}
          </section>
          <section className={'Home-Section-2'}>
            <OurRange componentType={'home'} />
          </section>
          <section className={'Home-Section-3'}>
            <VisionBoard />
          </section>
          {/* <SubscribeForm /> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
