import React, {Component} from 'react';
import CaseStudyCard from '../../../components/basic/CaseStudyCard';
import {v4 as uuid} from 'uuid';
export default class CSgrid extends Component {
  /**
   * CSgrid Class Component.
   * *This class component renders the case studies of the client as cards using grid css.
   * *Uses componentDidMount to fetch data from the site's WordPress API to populate content.
   * *Filters out data from API to format text correctly and send them to their right positions.
   * *Each case study from the API is mapped, spawning their own cards based on their Case Study and each Case Study Card is also sent all it's content.
   */
  constructor(props) {
    super(props);
    this.state = {
      gutterNumber: 0,
      posts: [],
    };
  }
  componentDidMount() {
    const appUrl =
      'https://www.kingdombranding.com/betas/p2c/wp-json/wp/v2/posts/?_embed';
    fetch(appUrl)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          posts: data,
          isLoaded: true,
        });
      })
      .catch(console.log);
  }
  componentWillUnmount() {
    this.setState({
      posts: [],
      isLoaded: false,
    });
  }
  render() {
    return (
      <div className={'Body-Container'}>
        <div className={'Case-Container'}>
          {this.state.posts.map((tile) => {
            var condensedCaseStudyTitle = tile.title.rendered.replace(
              /\s/g,
              '-',
            );
            return (
              <CaseStudyCard
                key={uuid()}
                ourWork={true}
                post={tile}
                summary={tile.excerpt.rendered.replace(/<\/?[^>]+>/gi, '')}
                href={`/case-studies/${condensedCaseStudyTitle}`}
                image={tile._embedded['wp:featuredmedia'][0].source_url}
                text={tile.title.rendered}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
