import React, {useState, createContext} from 'react';
export const CardColorContext = createContext();

export const CardColorProvider = (props) => {
  const [cardColors, setCardColors] = useState({
    hoverColor: 'pink',
    shadowColor: 'white',
  });
  return (
    <CardColorContext.Provider value={[cardColors, setCardColors]}>
      {props.children}
    </CardColorContext.Provider>
  );
};
