// Import Components
import React, {Component} from 'react';
import Footer from '../../components/Footer/Footer';
// Import Style
import './ThankyouPage.scss';
export class ThankyouPage extends Component {
  render() {
    return (
      <div className={'Thankyou-Page'}>
        <body className={'Thankyou-Body'} height={'100vh'}>
          <div className={'Thankyou-Column-Left'}>
            <h2 className={'Thankyou-Header-1'}>Thank you!</h2>
            <h1 className={'Thankyou-Header-2'}>For reaching out to us.</h1>
            <p className={'Thankyou-Header-Paragraph'}>
              We will get back to you as soon as possible and look forward to
              working with you.
            </p>
          </div>
        </body>
        <Footer />
      </div>
    );
  }
}

export default ThankyouPage;
