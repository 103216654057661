import React, {useState} from 'react';
import InstaIconBlack from '../../../assets/svgs/Social-Icons-03.svg';
import InstaIconBlue from '../../../assets/svgs/Social-Icons-03-blue.svg';
import InstaIconWhite from '../../../assets/svgs/Social-Icons-03-white.svg';
// import InstaIconRed from '../../../assets/svgs/Social-Icons-03-red.svg';
import './icon.css';

export default function InstaIcon(props) {
  const [isHovering, setIsHovering] = useState(Boolean);
  return (
    <div
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={{
        height: props.height,
        width: props.width,
        margin: '8px',
      }}>
      {props.color === 'white' ? (
        isHovering ? (
          <img src={InstaIconBlack} alt="Insta Icon" />
        ) : (
          <img src={InstaIconWhite} alt="Insta Icon" />
        )
      ) : props.color === 'black' ? (
        isHovering ? (
          <img src={InstaIconBlack} alt="Insta Icon" />
        ) : (
          <img src={InstaIconBlue} alt="Insta Icon" />
        )
      ) : null}
    </div>
  );
}
