import React, {Component} from 'react';
// Import Components
import OurRange from './components/OurRange';
import WWDHeading from './components/WWDHeader';
import Footer from '../../components/Footer/Footer';
// Import Styles
import appStyles from '../../styles/AppStyles';
import './WWDStyles.css';
import InfoBullet from './components/InfoBullet';
import appData from '../../assets/data/appData';
/**
 * @returns What We Do Page
 * @params {appData} Local Application Data
 */
export class WWDPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: 'Hello World',
    };
  }
  render() {
    return (
      <div className={'What-We-Do-Page'}>
        <div className={'What-We-Do-Hero'}>
          <WWDHeading paragraph={appData.company.philosophy} />
        </div>
        <div style={appStyles.body}>
          <section className={'Our-Range-Section'}>
            <OurRange />
          </section>
          <div className={'What-We-Do-Section-1-Container'}>
            <div className={'What-We-Do-Section-1-Info-Column'}>
              <InfoBullet
                id={'fundraising'}
                header={'Fundraising'}
                subHeader={'What Exactly'}
                paragraph={appData.whatWeDo.paragraphs.fundraising}
                bullets={appData.whatWeDo.bullets.fundraising}
              />
              <InfoBullet
                id={'communications'}
                header={'Communications'}
                subHeader={'What Exactly'}
                paragraph={appData.whatWeDo.paragraphs.communications}
                bullets={appData.whatWeDo.bullets.communications}
              />
            </div>
          </div>
          <div className={'What-We-Do-Section-2-Container'}>
            <div className={'What-We-Do-Section-2-Info-Column'}>
              <InfoBullet
                id={'generalConsulting'}
                header={'General Consulting'}
                subHeader={'What Exactly'}
                paragraph={appData.whatWeDo.paragraphs.generalConsulting}
                bullets={appData.whatWeDo.bullets.generalConsulting}
              />
              <InfoBullet
                id={'eventManagement'}
                header={'Event Management'}
                subHeader={'What Exactly'}
                paragraph={appData.whatWeDo.paragraphs.eventManagement}
                bullets={appData.whatWeDo.bullets.eventManagement}
              />
            </div>
          </div>
          <div className={'What-We-Do-Section-1-Container'}>
            <div className={'What-We-Do-Section-1-Info-Column'}>
              <InfoBullet
                id={'research'}
                header={'Research'}
                subHeader={'What Exactly'}
                paragraph={appData.whatWeDo.paragraphs.research}
                bullets={appData.whatWeDo.bullets.research}
              />
              <InfoBullet
                id={'development'}
                header={'Development'}
                subHeader={'What Exactly'}
                paragraph={appData.whatWeDo.paragraphs.development}
                bullets={appData.whatWeDo.bullets.development}
              />
            </div>
          </div>
          <img
            src="/assets/images/placeholders/chicago_flag.jpg"
            alt="flag"
            className="What-We-Do-Footer-Image"
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default WWDPage;
