import React from 'react';
import './hero.css';
import BasicButton from '../../../components/basic/BasicButton';
import TextLoop from 'react-text-loop';
import SocialMediaIcons from '../../../components/basic/SocialMediaIcons';

export default function Hero() {
  return (
    <div className={'Home-Hero-Container'}>
      <div className={'Row-Title'}>
        <h1 className={'Hero-Title'}>
          CHANGING THE WORLD FOR{' '}
          <TextLoop className={'Text-Loop'}>
            <div className={'Text-Loop-Text'}>
              THE BETTER
              <div className={'Text-Loop-Box'} />
            </div>
            <div className={'Text-Loop-Text'}>
              THE FUTURE
              <div className={'Text-Loop-Box'} />
            </div>
          </TextLoop>
        </h1>
      </div>
      <section className={'Hero-Bottom-Section'}>
        <SocialMediaIcons color={'black'} />
        <div className={'Change-Btn'}>
          <BasicButton
            buttonContainer={'Basic-Button-Container'}
            buttonType={'Basic-Button'}
            href={'/whatWeDo'}
            color={'rgb(238,52,34)'}
            text={'Create Change'}
            margin={10}
          />
        </div>
      </section>
    </div>
  );
}
