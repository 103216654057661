/* eslint-disable react/no-unescaped-entities */
import React, {Component} from 'react';
import {Slide} from 'react-reveal';
export class VisionBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: './assets/images/p2Logos/',
      projects: [
        'cfol.png',
        'chuy.png',
        'dusable.png',
        'elgie.png',
        'felix.png',
        'frerichs.jpg',
        'fritz.png',
        'jessewhite.png',
        'justice.png',
        'kennedy.png',
        'kim.png',
        'kwame.png',
        'larryrodgers.png',
        'rashid.png',
        'robin.png',
        'sophiaking.png',
      ],
    };
  }
  render() {
    return (
      <Slide up wait={100} delay={200} duration={1500}>
        <div className={'Vision-Board-Container'}>
          <div className={'Vision-Board'}>
            <h1 className={'Vision-Header'}>Visions We've Brought Together</h1>
            <div className={'Vision-Grid'}>
              {this.state.projects.map((project, index) => {
                return (
                  <img
                    className={'Vision-Board-Item'}
                    alt={project}
                    key={index}
                    src={`${this.state.path}${project}`}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Slide>
    );
  }
}

export default VisionBoard;
