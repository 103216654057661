import emailjs from '@emailjs/browser';

export default async function SendEmail(props) {
  emailjs.sendForm('p2consulting', 'contact_form', props.form.current, 'oo1rznvuWXPO81Nnt')
    .then(() => {
      window.open('/thankyou');
    }, (error) => {
      alert('Error Sending Contact Info, Please Try Again', error.message);
    });
}
