import React from 'react';
import Skyline from '../../../assets/images/chicago_skyline.jpg';
import arrowIcon from '../../../assets/svgs/iconmonstr-arrow-19.svg';
/**
 *
 * @param {props.nextPage} The Destination to the next case study page
 * @returns Next Case Study Button Section for bottom of Case Study Pages
 */
export default function NextCaseStudy(props) {
  // console.log(props);
  var condensedCaseStudyTitle = props.nextPage
    ? props.nextPage.title.rendered.replace(/\s/g, '-')
    : '/';
  return (
    <div className={'Next-Case-Study-Container'}>
      <div
        style={{backgroundImage: 'url(' + Skyline + ')'}}
        className={'Next-Case-Study-Image'}
      />
      <a
        href={`${condensedCaseStudyTitle}`}
        className={'Next-Case-Study-Button'}>
        <h4 className={'Next-Case-Study-Sub-Header'}>Next Case Study</h4>
        <h2 className={'Next-Case-Study-Title'}>
          {props.nextPage ? props.nextPage.title.rendered : 'Home'}
        </h2>
        <div className={'Arrow-Next-Container'}>
          <img src={arrowIcon} className={'Next-Arrow'} />
        </div>
      </a>
    </div>
  );
}
