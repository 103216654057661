import React from 'react';
import '../CaseStudyPage.css';
/**
 *
 * @param {props.subHeader} Sub Header for Elevating the Underdog
 * @param {props.paragraph} Paragraph Text data for function
 *
 * @returns Elevating the Underdog Section
 */
export default function ElevatingUnderdog(props) {
  return (
    <div className={'Elevating-Container'}>
      <div className={'Elevating-Column-Left'}>
        <h2 className={'Elevating-Heading'}>Elevating the underdog</h2>
        <h4 className={'Elevating-Sub-Heading'}>{props.subHeader}</h4>
        <p className={'Elevating-Campaign-Description'}>{props.paragraph}</p>
      </div>
      <div className={'Elevating-Column-Right'}>
        <h2 className={'How-We-Elevated-Heading'}>How we elevated</h2>
        <ul className={'Elevating-List'}>
          <li className={'Elevating-List-Item'}>Fundraising</li>
          <li className={'Elevating-List-Item'}>Communications</li>
          <li className={'Elevating-List-Item'}>General Consulting</li>
        </ul>
      </div>
    </div>
  );
}
