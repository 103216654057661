import React, {Component} from 'react';
import appStyles from '../../styles/AppStyles';
import Footer from '../../components/Footer/Footer';
import TeamHero from './components/TeamHero';
import TeamCard from './components/TeamCard';
import KatiePic from '../../assets/images/Katie.jpg';
import GenPic from '../../assets/images/Gennifer.jpeg';
export class TeamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: 'CARD',
      rows: 20,
      data: [0, 1, 2],
    };
  }
  render() {
    return (
      <div>
        <header>
          <TeamHero />
        </header>
        <div style={appStyles.body}>
          <div className="Team-Card-Container">
            {/* <TeamCard
              name={'Hanah Jubeh'}
              title={'President'}
              // answer1={'Answer'}
              // answer2={'Answer'}
              bio={
                'At just 22 years old, P2 Founder & CEO Hanah Jubeh began her career in the political area as chief of staff to an alderman. In the nearly two decades since, Hanah’s unique skill set has been in demand on the local, state, federal and international level. Hanah founded P2 because she saw the need for a different type of consulting firm, one that was wholly responsive to the needs of its clients. Her “all-in” philosophy has earned her a reputation as a trusted advisor and fiercely effective strategist. In September 2015, Crain’s Chicago Business named her one of the “Most Powerful Political Insiders” in Illinois. She has manageddozens of political campaigns at the municipal, county, state and federal levels. In 2011, Hanah was appointed by Governor Quinn to serve a second term to the Illinois Department of Employment Security. She has served on the Chicago 2016 Olympic Committee and currently is a board member of the Illinois Women’s Institute for Leadership and Chair of the IWIL Fundraising Committee.'
              }
              memberPortrait={`url(${HanahPic})`}
            /> */}
            <TeamCard
              name={'Katie Craig'}
              title={'President'}
              // answer1={'NYTimes Daily Briefing'}
              // answer2={'Life discovered on Mars'}
              bio={
                'Katie Craig assumed the role of President of P2 Consulting, Inc. in January 2023 after serving as Chief of Staff since 2021. Prior to joining P2, Katie served as the Head of Special Projects with the Illinois Department of Commerce & Economic Opportunity. Her resume includes extensive work in campaign management, professional fundraising, advocacy work, and paid campaign communications (digital and direct mail advertising). Katie has managed high-dollar fundraising and paid communications plans at the local, state, and national level for both political and nonprofit clients. A native of Tinley Park, Illinois, Katie comes from a long line of labor organizers and is a third-generation granddaughter of Mexican immigrants. Katie prioritizes using her fundraising, development, and project management skills to regularly support local organizations such as The National Immigrant Justice Center, Chicago Coalition for the Homeless, My Block My Hood My City, and, most recently, the Refugee Community Connection. Katie received her B.A. in International Relations with a concentration in Global Security from the University of Wisconsin-Madison and resides in Old Town with her husband and rescue pup, Ruthie, named for the late Supreme Court Justice Ruth Bader Ginsburg.'
              }
              memberPortrait={`url(${KatiePic})`}
            />
          </div>
          <div className="Team-Card-Container">
            <TeamCard
              name={'Gennifer Geer'}
              title={'Senior Associate'}
              // answer1={'Politico Playbook'}
              // answer2={'False Nuclear Emergency Alerts'}
              bio={
                // eslint-disable-next-line quotes
                "Gennifer Geer has served as Senior Associate with P2 since March 2022. Her experience advising on field, messaging, and fundraising has reinforced her commitment to building campaigns through careful organization and meaningful relationship-building. She's especially proud of her work training first-time candidates as the Organizing Director of Lake County Democratic Women, where she managed 38 municipal campaigns. Beyond her political work, Gennifer is pursuing a Ph.D. in history at Northwestern University, studying Asian American and Indigenous history. She is proudly second-generation Japanese American and holds a B.A. in History and Asian Studies from Rice University. Gennifer also serves as Vice President for the Young Democrats of Illinois and as board member of the Democratic Party of Evanston. She resides in Evanston with her cat."
              }
              memberPortrait={`url(${GenPic})`}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TeamPage;
