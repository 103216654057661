import React, {Component} from 'react';
import '../ourWork.css';

export default class OurWorkHero extends Component {
  /**
   * OurWorkHero class component.
   * *This class component renders the hero of the Our Work page.
   */
  render() {
    return (
      <div className={'Hero-Container'}>
        <div className={'Heading-Column'}>
          {/* <div className={'Heading-1-Row'}>
            <h3 className={'Heading-1'}>Our Work</h3>
          </div> */}
          <div className={'Heading-2-Row'}>
            <div className={'Heading-2'}>
              We pride ourselves in bringing excellent solutions
            </div>
          </div>
        </div>
      </div>
    );
  }
}
