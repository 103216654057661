// import bg from '../assets/images/bg.jpg';

const appStyles = {
  // Colors
  colors: {
    primary: 'rgb(238,52,34)',
    secondary: 'rgb(247,177,23)',
    accent: 'rgb(247,177,23)',
    black: 'black',
    white: 'white',
    grey: 'rgb(83,83,83)',
    lightGrey: 'rgb(175,175,175)',
    skyblue: '#6c90de',
  },
  app: {
    textAlign: 'center',
  },
  appLogo: {
    height: '40vim',
    pointerEvents: 'none',
  },
  rowContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnContainer2: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default appStyles;
