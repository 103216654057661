/* eslint-disable react/no-unknown-property */
import React, {useEffect, useState} from 'react';
import '../mtt.scss';
import AnimateOnScroll from '../../../logic/AnimateOnScroll';
// const backImage = './assets/images/placeholders/chicago_skyline.jpg';
export default function TeamHero() {
  /**
   * TeamHero Class Component.
   * *This class component renders the hero of the Meet the Team page.
   */

  const [animateteamhero, setAnimateTeamHero] = useState(0);
  const [ref, visible] = AnimateOnScroll({rootMargin: '50px'});
  useEffect(() => {
    if (visible === true) {
      setAnimateTeamHero(1);
    }
    return () => {
      setAnimateTeamHero(0);
    };
  }, [visible]);
  return (
    <div className={'Team-Jumbo'}
      ref={ref}
    >
      <div className={'Team-Heading-Container'}
        animateteamhero={animateteamhero}
      >
        <h4 className={'Team-Title-1'}>Behind p2</h4>
        <h1 className={'Team-Title-2'}>Meet the Team</h1>
      </div>
      <div className={'Pic-Container'}
        animateteamhero={animateteamhero}
      />
    </div>
  );

}