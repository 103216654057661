import React, {useState} from 'react';
import FacebookIconBlack from '../../../assets/svgs/Social-Icons-01.svg';
import FacebookIconBlue from '../../../assets/svgs/Social-Icons-01-blue.svg';
import FacebookIconWhite from '../../../assets/svgs/Social-Icons-01-white.svg';
// import FacebookIconRed from '../../assets/svgs/Social-Icons-01-red.svg';
import './icon.css';

export default function FacebookIcon(props) {
  const [isHovering, setIsHovering] = useState(Boolean);
  return (
    <div
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={{
        height: props.height,
        width: props.width,
        margin: '8px',
      }}>
      {props.color === 'white' ? (
        isHovering ? (
          <img src={FacebookIconBlack} alt="Facebook Icon" />
        ) : (
          <img src={FacebookIconWhite} alt="Facebook Icon" />
        )
      ) : props.color === 'black' ? (
        isHovering ? (
          <img src={FacebookIconBlack} alt="Facebook Icon" />
        ) : (
          <img src={FacebookIconBlue} alt="Facebook Icon" />
        )
      ) : null}
    </div>
  );
}
