import React from 'react';
import {HashLink} from 'react-router-hash-link';
// import AnimateOnScroll from '../../logic/AnimateOnScroll';
export default function OurRange(props) {
  // const [animaterange, setanimaterange] = useState(0);
  // const [ref, visible] = AnimateOnScroll({rootMargin: '-100px'});
  // useEffect(() => {
  //   if (visible) {
  //     setanimaterange(1);
  //   } else {
  //     setanimaterange(0);
  //   }
  // }, [visible]);
  return (
    <div
      // ref={ref}
      // animaterange={animaterange}
      className={
        props.containerType === 'home'
          ? 'Our-Range-Container-1'
          : 'Our-Range-Container-2'
      }>
      <div className={'Our-Range-Header-Container'}>
        <h2 className={'Our-Range-Header'}>Our Range</h2>
        <div className={'Our-Range-Header-Underline'} />
      </div>
      <div className={'Our-Range-Grid'}>
        <HashLink
          className={'Our-Range-Grid-Item'}
          to={'/whatWeDo#fundraising'}>
          Fundraising
        </HashLink>
        <HashLink
          className={'Our-Range-Grid-Item'}
          to={'/whatWeDo#communications'}>
          Communications
        </HashLink>
        <HashLink
          className={'Our-Range-Grid-Item'}
          to={'/whatWeDo#generalConsulting'}>
          General Consulting
        </HashLink>
        <HashLink
          className={'Our-Range-Grid-Item'}
          to={'/whatWeDo#eventManagement'}>
          Event Management
        </HashLink>
        <HashLink className={'Our-Range-Grid-Item'} to={'/whatWeDo#research'}>
          Research
        </HashLink>
        <HashLink
          className={'Our-Range-Grid-Item'}
          to={'/whatWeDo#development'}>
          Development
        </HashLink>
      </div>
    </div>
  );
}
